import React from "react";
import PropTypes from "prop-types"
import ReactDOM from "react-dom";

import "./accordion.sass"

class Accordion extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isActive: false, height: "0" };
    }

    toggle() {
        if (this.state.isActive) {
            this.setState({
                height: "0",
                isActive: false
            });
        } else {
            const thisEl = ReactDOM.findDOMNode(this);
            const height = thisEl.getElementsByClassName('accordion--container--content')[0].scrollHeight;
            this.setState({
                height: height + 'px',
                isActive: true
            });
        }
    }

    render() {
        const {isActive, height} = this.state;
        const {title} = this.props;

        return (
            <div
                className={isActive ? 'accordion active' : 'accordion'}
            >
                <div className="accordion--header" onClick={() => {this.toggle()}}>
                    <p>{title}</p>
                </div>
                <div className="accordion--container" style={{height: height}}>
                    <div className="accordion--container--content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

Accordion.propTypes = {
    children: PropTypes.node,
};

export default Accordion
