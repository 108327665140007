import React from "react";

import "./member.sass"

class Member extends React.Component {

    render() {
        const {node} = this.props;

        return (
            <div className="member">
                <div className="grid">
                    <div className="member--person col-3 col-12td col-12md col-12smd">

                        <img src={node.frontmatter.image} alt={node.frontmatter.image_label}/>
                        <h6 className="member--person--name">{node.frontmatter.image_label}</h6>
                    </div>
                    <div className="member--company col-5 col-12td col-12md col-12smd">
                        <h6 className="member--company-name">{node.frontmatter.title}</h6>
                        {node.html &&
                        <p className="md" dangerouslySetInnerHTML={{__html: node.html}}></p>
                        }
                    </div>
                    <div className="member--address col-4 col-12td col-12md col-12smd">
                        <p className="md" dangerouslySetInnerHTML={{__html: (node.frontmatter.body_right || '').replace(/\\\n/g, '\n')}}></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Member
