import React from "react";
import PropTypes from "prop-types"

import "../become-member-small/become-member-small.sass"

const BecomeMemberSmall = ({children}) => {

    return (
        <div
            className={'become-member-small section'}
        >
            <div className={'fourth-container become-member-small--content'}>
                <h3>Werden auch Sie Mitglied im HGV</h3>
                <a className={'button'} href="/beitreten">MITGLIED WERDEN</a>
            </div>
        </div>
    )
};

BecomeMemberSmall.propTypes = {
    children: PropTypes.node,
};

export default BecomeMemberSmall
