import React, {Component} from "react"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Accordion from "../../components/accordion/accordion";

import './mitglieder.sass'
import {graphql} from "gatsby";
import BecomeMemberSmall from "../../components/become-member-small/become-member-small";
import Member from "../../components/member/member";

export default class MitgliederPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            query: ``,
        }
    }

    render() {
        const { data } = this.props;

        let filtered = data.members.edges;

        const regex = /[^\w]/g;
        if (this.state.query) {
            filtered = filtered.filter(item => {
                return item.node.frontmatter.title.replace(regex, '').toLowerCase().includes(this.state.query.replace(regex, '').toLowerCase());
            });
        }

        filtered.sort((a, b) => {
            const aString = a.node.frontmatter.title.replace(regex, '').toLowerCase();
            const bString = b.node.frontmatter.title.replace(regex, '').toLowerCase();
            if (aString < bString)
                return -1;
            if (aString > bString)
                return 1;
            return 0;
        });

        return (
            <Layout>
                <SEO title="Mitglieder"/>
                <div className={'memberPage container section'}>

                    <h1 className={'title'}>Mitglieder</h1>
                    <h4 className={'subtitle'}>Mitglieder von A bis Z</h4>
                    <div className="text-container memberSwitch">
                        <p>A-Z&nbsp;-&nbsp;<a href="/mitglieder/branchen">Branchen</a></p>
                        <input className={'search-input'} type="text" placeholder={"Suche"} value={this.state.query} onChange={this.search} />
                    </div>
                    <div className="fourth-container">
                        {filtered.map((item, i) => {
                            return (<Accordion key={i} title={ item.node.frontmatter.title }>
                                <Member node={item.node}/>
                            </Accordion>)
                        })}
                    </div>
                </div>
                <BecomeMemberSmall></BecomeMemberSmall>
            </Layout>
        );
    }



    search = event => {
        const query = event.target.value;
        this.setState(s => {
            return {
                query,
            }
        })
    }

}


export const pageQuery = graphql`
    query MembersQuery {
      members: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "//members//"}}
      ) {
        edges {
          node {
            html
            rawMarkdownBody
            frontmatter {
              title
              image
              image_label
              body_right
            }
          }
        }
      }
    }
  `;
